* {
  margin: 0;
  padding: 0;
}

/* html, body, #root */
html {
  background-color: #E4D4C8;
}

.app {
  text-align: center;
}

#home-page {
  /* background-color: #D0B49F; */
  /* Sand Dollar
    #E4D4C8
    Tan
    #D0B49F
    Brown
    #A47551
    Carafe
    #523A28 */
}

#unity-container {
  width: 100%; /* 占满可用宽度 */
  height: 100vh; /* 占满视口高度 */
  max-width: 600px; /* 最大宽度，可以根据实际需要调整 */
  margin: auto; /* 水平居中显示 */
  position: relative; /* 相对定位 */
  background-color: white;
}